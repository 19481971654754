@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
  background-color: #161718;
}
body::-webkit-scrollbar {
  width: 10px; /* ширина scrollbar */
}
body::-webkit-scrollbar-track {
  background-color: #161718; /* цвет дорожки */
}
body::-webkit-scrollbar-thumb {
  background-color: #98ee02; /* цвет плашки */
  border-radius: 20px; /* закругления плашки */
  /* padding вокруг плашки */
}
