.text-wrapper {
  color: white;
  font-size: 8.6vw;
  font-family: "Coolvetica Rg";
  font-weight: 500;
  letter-spacing: 3.3px;
  word-wrap: break-word;
  line-height: 7.1vw;
}
.lineWrap {
  background: rgba(152, 238, 2, 1);
  width: 9vw;
  height: 3px;
  margin-bottom: 1vw;
  margin-top: 2.5vw;
}
.text-wrapper-2 {
  font-size: 8.6vw;
  font-family: "Coolvetica Rg";
  font-weight: 500;
  letter-spacing: 3.3px;
  word-wrap: break-word;
  color: transparent;
  margin-left: 1vw;
  background: linear-gradient(
    167deg,
    rgba(255, 255, 255, 0.31) 27.42%,
    rgba(255, 255, 255, 0.72) 38.13%,
    #fff 60.68%,
    rgba(175, 232, 2, 1) 99%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.overlap-wrapper {
  position: relative;
}
.text-wrapper-5 {
  color: white;
  font-size: 1.2vw;
  font-family: "Coolvetica Rg";
  font-weight: 500;
  z-index: 2;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.44px;
  word-wrap: break-word;
  margin-top: 0.6vw;
}
.div-wrapper .lineWrap {
  margin: 0.7vw 0;
  width: 4.1vw;
  height: 2px;
}
.div-wrapper {
  background: url(./vector.png);
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  padding: 1vw;
  cursor: pointer;
  transition: 0.5s;
}
.div-wrapper:hover {
  background: url(./hover.png);
  background-size: 100%;
  background-repeat: no-repeat;
}
.text-wrapper-6 {
  display: flex;
  align-items: center;
  color: white;
  font-size: 0.8vw;
  font-family: "Gilroy";
  font-weight: 300;
  letter-spacing: 0.28px;
  margin-top: 0.3vw;
}
.casesCont {
  display: flex;
  margin-top: 2.3vw;
  width: 100%;
  justify-content: space-between;
}
.text-wrapper-6 img {
  width: 1.5vw;
  margin-left: 0.5vw;
  margin-top: 0.3vw;
}
.overlap-group-wrapper {
  background: url(./big.png);
  transition: 0.5s;
  width: 26.5vw;
  padding: 1.5vw;
  background-size: 100%;
  background-repeat: no-repeat;
}
.overlap-group-wrapper:hover {
  transform: translateY(-10px);
  cursor: pointer;
}
.overlap-2 .lineWrap {
  margin: 1vw 0;
}
.element {
  color: #fff;

  font-family: "Gilroy";
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
}
.text-wrapper-4 {
  margin-top: 2vw;
  display: flex;
  align-items: center;
  color: #fff;

  font-family: "Gilroy";
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
}
.text-wrapper-4 img {
  margin-left: 0.5vw;
  margin-top: 0.3vw;
  width: 2vw;
}
.dallas {
  color: #fff;
  margin-top: 1.5vw;
  font-family: "Coolvetica Rg";
  font-size: 1.7vw;
  z-index: 2;
  position: relative;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
}
.overlap-group-wrapper > img {
  width: 100%;
  filter: drop-shadow(0px 8px 45px rgba(26, 23, 40, 0.8));
}
.p {
  color: white;
  font-size: 0.8vw;
  font-family: "Gilroy";
  font-weight: 300;
  letter-spacing: 0.28px;
  word-wrap: break-word;
}
.rightCases {
  display: grid;
  grid-column-gap: 2vw;
  grid-row-gap: 2vw;
  grid-template-columns: 17.2vw 17.2vw;
}
.div-wrapper > img {
  width: 100%;
  filter: drop-shadow(0px 8px 45px rgba(26, 23, 40, 0.8));
}
.vector {
  position: absolute;
  top: 1vw;
  width: 18vw;
  left: 31vw;
}
.group {
  width: 65vw;
}
.realize {
  display: flex;
}
.text-wrapper-3 {
  color: rgba(255, 255, 255, 0.15);
  font-size: 2.6vw;
  font-family: "Coolvetica Rg";
  font-weight: 500;
  text-transform: lowercase;
  line-height: 2.5vw;
  letter-spacing: 0.99px;
  word-wrap: break-word;
}
.box {
  background: url(./main.png);
  background-size: cover;
  display: flex;
  background-position-x: -2.5vw;
  flex-direction: column;
  align-items: center;
  padding: 6vw 0;
  padding-bottom: 8vw;
}
.text-wrapper-2 {
}
.popVideo {
  background: rgba(26, 23, 40, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 55;
}
@media (min-width: 640px) {
  .overlap-group-wrapper {
    transition: 0.5s;
    position: relative;
  }
  .overlap-group-wrapper:hover {
    top: -5px;
  }
}
@media (max-width: 640px) {
  .casesCont {
    display: flex;
    margin-top: 2.3vw;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
  }
  .group {
    width: 94vw;
  }
  .overlap-group-wrapper {
    transition: 0.5s;
    width: 100%;
    padding: 5.5;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .dallas {
    color: #fff;
    margin-top: 1.5vw;
    font-family: "Coolvetica Rg";
    font-size: 8vw;
    z-index: 2;
    position: relative;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.64px;
    text-transform: uppercase;
  }
  .overlap-2 .lineWrap {
    width: 35vw;
    margin: 5vw 0 !important;
  }
}
