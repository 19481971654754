.branding {
  color: #3a3a3a;

  font-family: "Coolvetica Rg";
  font-size: 8vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.068px;
  line-height: 8vw;
}
.designText {
  color: #3a3a3a;
  line-height: 9vw;
  font-family: "Coolvetica Rg";
  font-size: 11vw;
  font-style: normal;
  font-weight: 400;
  background: linear-gradient(167deg, #3a3a3a 68.68%, rgba(175, 232, 2, 1) 99%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 4.014px;
}
.brandingDesignBody {
  width: 65vw;
  margin: 0 auto;
  background: #f4f9fd;
}
.brandingDesignBody .component {
  margin-bottom: 0;
}
.BrandingDesign {
  background: #f4f9fd;
  padding: 3vw 0;
  padding-bottom: 0;
  width: 100%;
}
.design {
  display: flex;
  margin-bottom: 1.5vw;
}
.bigImage ul {
  list-style-type: none;
  width: fit-content;
  padding: 2vw;
  border-radius: 2vw;
  box-shadow: 0px 0px 70px 0px rgba(131, 123, 180, 0.42);
  background: #fff;
  margin-bottom: 45vw;
}
ul {
  list-style-type: none;
}
.bigImage ul li:hover {
  scale: 1.05;
}
.bigImage ul li {
  display: flex;
  align-items: center;
  color: #3a3a3a;
  transition: 0.3s;
  text-align: center;
  font-family: "Gilroy";
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 700;

  line-height: normal;
  padding: 0.8vw 0;
  padding-right: 1vw;
  border-bottom: 1px solid #d6d6d6;
}
.bigImage ul li:last-child {
  border-bottom: 0 !important;
}
.stand1,
.stand2,
.stand3,
.stand4,
.stand5 {
  position: absolute;
  transition: 0.5s !important;
  cursor: pointer;
}
.noDemp {
  display: flex;
  align-items: center;
  margin-bottom: 7vw;
}
.noDemp > img {
  width: 30vw;
  margin-right: 3vw;
  filter: drop-shadow(0px 0px 41.84782px 0px rgba(131, 123, 180, 0.42));
}
.dalp {
  color: rgba(49, 44, 103, 0.15);
  display: flex;
  align-items: center;
  font-family: "Coolvetica Rg";
  font-size: 2.1vw;
  font-style: normal;
  font-weight: 400;
  margin-right: 0.5vw;
  letter-spacing: 0.82px;
  text-transform: lowercase;
  margin-top: 1vw;
}
.dalp img {
  margin-top: 0.5vw;
  width: 3vw;
}
.rightDemp > p {
  color: #3a3a3a;
  margin-top: 2vw;
  font-family: "Coolvetica Rg";
  font-size: 3.6vw;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 1.365px;
  text-transform: uppercase;
}
.rightDemp > span {
  color: #735bff;
  font-family: "Coolvetica Rg";
  font-size: 1.9vw;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 2vw;
  display: block;
  letter-spacing: 0.725px;
  text-transform: uppercase;
}
.stand1 {
  top: 40.5vw;
  width: 17.5vw;
  left: -6.5vw;
}
.stand:hover {
  margin-top: -10px;
}
.stand3 {
  top: 61vw;
  left: 2.5vw;
  width: 32vw;
}
.lights {
  position: absolute;
  top: 53.3vw;
  left: 15.4vw;
  width: 23.7vw;
  animation: morg 5s infinite;
  transition: 0s;
}
.stand2 {
  top: 70.5vw;
  left: 4vw;
  width: 17vw;
  z-index: 3;
}
.stand1 img {
  width: 100%;
}
.stand4 {
  top: 57vw;
  left: 27.3vw;
  width: 37vw;
}
.stand5 {
  top: 57vw;
  width: 12vw;
  left: 49vw;
}
.bigFon {
  width: 80vw;
  left: -3.5vw;
  top: -0.7vw;
  position: absolute;
}
.book {
  position: absolute;
  top: -8.5vw;
  right: 5.5vw;
  width: 35vw;
  animation-name: floating;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}
.bigImage li img {
  width: 2.8vw;
  margin-right: 0.7vw;
}
.bigImage {
  position: relative;
}
.structure {
  color: rgba(0, 0, 0, 0.15);
  margin-right: 1.5vw;
  font-family: "Coolvetica Rg";
  font-size: 2.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 2.3vw;
  letter-spacing: 0.877px;
  text-transform: lowercase;
}
.brandingDesignBody > p {
  color: #735bff;

  font-family: "Coolvetica Rg";
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 3vw;
  letter-spacing: 0.72px;
  text-transform: lowercase;
}
@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
@keyframes morg {
  0% {
    opacity: 1;
  }
  8% {
    opacity: 1;
  }
  16% {
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
  44% {
    opacity: 1;
  }
  46% {
    opacity: 0;
  }
  48% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  52% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes zoomMe {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.05);
  }
  40% {
    transform: scale(1);
  }
}
@media (max-width: 640px) {
  .brandingDesignBody {
    width: 94vw;
    margin: 0 auto;
    background: #f4f9fd;
  }
  .brandingDesignBody .component {
    margin: 5vw auto;
    margin-bottom: 0;
  }
  .branding {
    color: #3a3a3a;
    font-family: "Coolvetica Rg";
    font-size: 15vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3.068px;
    line-height: 15vw;
  }
  .brandingDesignBody {
    padding-bottom: 15vw;
  }

  .BrandingDesign .aboutLine {
    margin: 0;
    margin-bottom: 10vw;
  }
  .bus .branding {
    font-size: 13.4vw;
  }

  .structure {
    color: rgba(0, 0, 0, 0.15);
    margin-right: 1.5vw;
    font-family: "Coolvetica Rg";
    font-size: 4.3vw !important;
    font-style: normal;
    font-weight: 400;
    line-height: 4vw;
    letter-spacing: 0.877px;
    text-transform: lowercase;
  }
  .rightDemp > p {
    color: #3a3a3a;
    margin-top: 2vw;
    font-family: "Coolvetica Rg";
    font-size: 9.6vw;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1.365px;
    text-transform: uppercase;
  }
  .rightDemp > span {
    color: #735bff;
    font-family: "Coolvetica Rg";
    font-size: 4.9vw;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 2vw;
    display: block;
    letter-spacing: 0.725px;
    text-transform: uppercase;
  }
  .noDemp {
    flex-direction: column-reverse;
  }
  .designText {
    color: #3a3a3a;
    line-height: 14vw;
    font-family: "Coolvetica Rg";
    font-size: 16vw;
    font-style: normal;
    font-weight: 400;
    background: linear-gradient(
      167deg,
      #3a3a3a 68.68%,
      rgba(175, 232, 2, 1) 99%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 4.014px;
  }
  .structure {
    color: rgba(0, 0, 0, 0.15);
    margin-right: 1.5vw;
    font-family: "Coolvetica Rg";
    font-size: 5.3vw;
    font-style: normal;
    font-weight: 400;
    line-height: 4vw;
    letter-spacing: 0.877px;
    text-transform: lowercase;
  }
  .brandingDesignBody > p {
    color: #735bff;
    font-family: "Coolvetica Rg";
    font-size: 4.8vw;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 7vw;
    letter-spacing: 0.72px;
    text-transform: lowercase;
  }
  .design .lineWrap {
    margin-top: 3vw;
  }
  .bigImage ul li {
    display: flex;
    align-items: center;
    color: #3a3a3a;
    transition: 0.3s;
    text-align: center;
    font-family: "Gilroy";
    font-size: 4.2vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 2.8vw 0;
    padding-right: 1vw;
    border-bottom: 1px solid #d6d6d6;
  }
  .bigImage ul {
    width: 100%;
  }
  .bigImage li img {
    width: 9.8vw;
    margin-right: 2.7vw;
  }
  .bigMob {
    width: 100vw;
    margin-left: -3vw;
  }
  .bigImage ul {
    list-style-type: none;

    padding: 5vw;
    border-radius: 7vw;
    box-shadow: 0px 0px 70px 0px rgba(131, 123, 180, 0.42);
    background: #fff;
    margin-bottom: 5vw;
  }
  .branding {
    margin-top: 10vw;
  }
}
