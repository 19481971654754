.promoComp {
  background: #f4f9fd;
}
.promoCompBody {
  width: 65vw;
  margin: 0 auto;
  padding-top: 3vw;
}
.promoCompBody .designText {
  font-size: 8vw;
}
.bus .designText {
  font-size: 7vw;
}
.zoomMe {
  transition: 0.5s;
}
.zoomMe:hover {
  translate: 0 -5px;
}
.arrowAbs {
  position: absolute;
  width: 22vw !important;
  right: 20vw;
  bottom: -1.5vw;
}
.withButton ul {
  margin-top: 4vw;
}
.withButton {
  padding-bottom: 3vw;
}
.withButton .priceButton {
  padding: 2vw 6vw;
}
.promoTxt {
  color: #735bff;

  font-family: "Coolvetica Rg";
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 400;
  margin: 1.5vw 0;
  letter-spacing: 0.72px;
  text-transform: lowercase;
}
.promoCompBody ul li:hover {
  scale: 1.05;
}
.imgs {
  width: 100%;
  margin-bottom: 2vw;
  cursor: text;
}
.an {
  display: flex;
  justify-content: space-between;
}
.newPromo {
  width: 30vw !important;
}
.an p {
  color: #3a3a3a;

  font-family: "Coolvetica Rg";
  font-size: 3.2vw;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 1.28px;
  text-transform: uppercase;
  margin-top: 1.5vw;
}
/* .bus .designText {
  font-size: 7.4vw;
}
.bus .branding {
  font-size: 11.4vw;
} */
.bus .lineWrap {
  margin-top: 1.5vw;
  margin-bottom: 0.5vw;
}
/* .design .structure {
  font-size: 3vw !important;
} */
/* .promoCompBody .designText {
  font-size: 8.5vw !important;
}
.bus .designText {
  font-size: 9.4vw !important;
} */
.an span {
  color: #735bff;
  font-family: "Coolvetica Rg";
  font-size: 1.9vw;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.72px;
  text-transform: uppercase;
  padding-bottom: 1vw;
  border-bottom: 2px solid rgba(5, 5, 5, 0.17);
}
.leftAn {
  display: flex;
  flex-direction: column;
}
.leftAn div {
  color: #3a3a3a;
  padding-top: 0.5vw;
  font-family: "Gilroy";
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.64px;
}
.arrImg img {
  width: 100%;
}
.arrImg {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 2vw;
  grid-row-gap: 2vw;
  margin-bottom: 4vw;
}
.promoCompBody ul {
  width: fit-content;
}
.promoCompBody ul li img {
  margin-right: 1vw;
  width: 2.5vw;
}
.promoContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.getCh {
  position: absolute;
  right: 0;
  width: 33vw;
  top: 0;
  animation: getCh 4s infinite;
}
.testic {
  position: absolute;
  right: 2vw;
  top: 1vw;
  width: 33vw;
  animation: rots 4s infinite;
}
.noBord {
  border: none !important;
}
.promoContent .priceButton {
  margin-top: 3vw;
}
@keyframes rots {
  0% {
    scale: 1;
    translate: 0;
  }
  50% {
    transform: skew(10px);

    rotate: 5deg;
  }
  75% {
    scale: 1;
    rotate: 0deg;
    translate: 0;
  }
  100% {
    scale: 1;
    translate: 0;
  }
}
@keyframes getCh {
  0% {
    scale: 1;
    translate: 0;
  }
  50% {
    scale: 1.07;
    translate: 10px;
  }
  75% {
    scale: 1;
    translate: 0;
  }
  100% {
    scale: 1;
    translate: 0;
  }
}
.promoContent img {
  width: 33vw;
}
.promoCompBody ul li {
  display: flex;
  align-items: center;
  color: #3a3a3a;
  transition: 0.3s;
  text-align: center;
  font-family: "Gilroy";
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 700;

  line-height: normal;
  padding: 0.8vw 0;
  padding-right: 1vw;
  border-bottom: 1px solid #d6d6d6;
}
.promoCompBody .component {
  border-radius: 2vw;
}
.promoCompBody ul li:last-child {
  border-bottom: 0 !important;
}
.promoCompBody ul li:last-child {
  border-bottom: 0 !important;
}
@media (max-width: 640px) {
  .promoCompBody {
    width: 94vw;
  }
  .promoTxt {
    color: #735bff;
    font-family: "Coolvetica Rg";
    font-size: 5.8vw;
    font-style: normal;
    font-weight: 400;
    margin: 1.5vw 0;
    letter-spacing: 0.72px;
    text-transform: lowercase;
  }
  .promoContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
  }
  .promoCompBody ul li {
    display: flex;
    align-items: center;
    color: #3a3a3a;
    transition: 0.3s;
    text-align: center;
    font-family: "Gilroy";
    font-size: 4vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 2.8vw 0;
    padding-right: 1vw;
    border-bottom: 1px solid #d6d6d6;
  }
  .promoContent img {
    width: 100%;
  }
  .an {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .an p {
    color: #3a3a3a;
    font-family: "Coolvetica Rg";
    font-size: 13.2vw;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1.28px;
    text-transform: uppercase;
  }
  .an span {
    color: #735bff;
    font-family: "Coolvetica Rg";
    font-size: 7.5vw;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.72px;
    text-transform: uppercase;
    padding-bottom: 3vw;
    border-bottom: 2px solid rgba(5, 5, 5, 0.17);
  }
  .promoComp .priceButton {
    padding: 6vw;
    width: 100%;
  }
  .arrImg {
    display: grid;
    grid-template-columns: 45vw 45vw 45vw 45vw;
    grid-column-gap: 4vw;
    grid-row-gap: 4vw;
    transition: 0.3s;
    margin-bottom: 4vw;
    margin-top: 10vw;
  }
  .testic {
    position: absolute;
    right: 11vw;
    top: 129vw;
    width: 33vw;
    animation: rots 4s infinite;
  }
  .newPromo {
    width: 100% !important;
  }
  .promoCompBody .sliderButtons div {
    border: 3px solid #806bff;
  }
  .promoCompBody .component {
    margin: 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 6vw;
    overflow: hidden;
  }
  .promoCompBody .idea p {
    padding: 0 3vw;
  }
  .an img {
    width: 100%;
    margin-top: 8vw;
  }
  .leftAn div {
    color: #3a3a3a;
    padding-top: 0.5vw;
    font-family: "Gilroy";
    font-size: 6.6vw;
    font-style: normal;
    font-weight: 400;
    margin-top: 2vw;
    line-height: normal;
    letter-spacing: 0.64px;
  }
  .getCh {
    position: absolute;
    right: 0;
    width: 33vw;
    top: 88vw;
    animation: getCh 4s infinite;
  }
  .promoCompBody ul {
    width: 100%;
    margin-top: 8vw;
  }
  .promoCompBody ul li img {
    margin-right: 3vw;
    width: 9.5vw;
  }
}
