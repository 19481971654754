.work {
  background: url(./fon.png);
  background-size: cover;
  background-color: #f4f9fd;
  background-position: center;
}
.workBody {
  width: 65vw;
  margin: 0 auto;
  padding: 5vw 0;
  padding-bottom: 8vw;
}
.workBody > img {
  width: 100%;
}
.last .priceButton {
  color: #1a1a1a;
  width: 19vw;
  text-align: center;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.96px;
  text-transform: uppercase;
}
.allSheme img {
  width: 1.8vw;
  margin-left: 0.5vw;
  margin-top: 0.3vw;
}
.chemedown > img {
  width: 17.5vw;
}
.last {
  display: flex;
  justify-content: space-between;
}
.last > div {
  position: relative;
}
.last > div > p {
  color: #fff;

  font-family: "Coolvetica Rg";
  font-size: 1.8svw;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.719px;
  text-transform: lowercase;
}
.dalen {
  margin-bottom: 2vw;
  width: 14vw !important;
}
.last > div img {
  width: 10vw;
  position: absolute;
  top: -2.5vw;
  left: 32vw;
}
.last > div > span {
  color: #a4ff00;
  font-family: "Coolvetica Rg";
  font-size: 2.3vw;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 2vw;
  display: block;
  letter-spacing: 0.899px;
  text-transform: lowercase;
}
.chemedown {
  margin-top: -2vw;
  margin-left: 17.1vw;
}
.allSheme {
  color: #fff;
  cursor: pointer;
  font-family: "Gilroy";
  font-size: 1.05vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 1.5vw;
  border-radius: 1.5vw;
  margin-right: 1.5vw;
  background: radial-gradient(
      82.26% 64.39% at 80.02% -31.91%,
      rgba(255, 255, 255, 0.2) 46.17%,
      rgba(68, 68, 68, 0) 100%
    ),
    #262829;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 54px 0px rgba(0, 0, 0, 0.44);
}
.chemedown {
  display: flex;
  align-items: flex-end;
}

.last {
  flex-direction: column;
}

.tap {
  color: #fff;

  font-family: "Coolvetica Rg";
  font-size: 8.5vw;
  font-style: normal;
  line-height: 7.5vw;
  font-weight: 400;

  letter-spacing: 3.3px;
}
.tapCont {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 3vw;
}

.last > div img {
  width: 18vw;
  position: absolute;
  top: 29.5vw;
  rotate: 36deg;
  left: 46vw;
}

.rightCont {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.rightCont ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.rightCont p {
  color: #fff;
  text-align: right;
  font-family: "Coolvetica Rg";
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.64px;
  text-transform: uppercase;
}
.example {
  color: rgba(255, 255, 255, 0.15);

  font-family: "Coolvetica Rg";
  font-size: 2.6vw;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.99px;
  text-transform: lowercase;
}
.lineTap {
  width: 5.3vw;
  height: 1px;
  background: rgba(214, 214, 214, 0.16);
  margin: 1vw 0;
}
.rightCont > .lineTap {
  background: #98ee02 !important;
}
.rightCont li {
  color: #fff;

  text-align: right;
  font-family: "Gilroy";
  font-size: 1.05vw;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.4px;
}
.lineGr {
  height: 3px;
  width: 33vw;
  background: #98ee02;
  margin: 3vw 0;
  margin-bottom: 2vw;
}
.rab {
  display: flex;
  align-items: center;
}
.rab img {
  width: 7vw;
  margin-top: 0.5vw;
  margin-right: 1vw;
}
.rab span {
  background: linear-gradient(
    157deg,
    rgba(255, 255, 255, 0.12) 15.78%,
    rgba(255, 255, 255, 0.72) 43.39%,
    #fff 80.98%,
    rgba(175, 232, 2, 1) 95%
  );
  font-family: "Coolvetica Rg";
  background-clip: text;
  -webkit-background-clip: text;
  font-size: 8.5vw;
  -webkit-text-fill-color: transparent;
  line-height: 7vw;
}
@media (max-width: 640px) {
  .workBody {
    width: 94vw;
    margin: 0 auto;
    padding: 5vw 0;
    padding-bottom: 8vw;
  }
  .dalen {
    margin-bottom: 2vw;
    margin-top: 7vw;
    width: 46vw !important;
  }
  .last > div > span {
    color: #a4ff00;
    font-family: "Coolvetica Rg";
    font-size: 8.3vw;
    font-style: normal;
    margin-top: 2vw;
    display: block;
    font-weight: 400;
    letter-spacing: 0.899px;
    text-transform: lowercase;
  }
  .chemedown > img {
    width: 46.5vw;
  }
  .last > div > p {
    color: #fff;
    font-family: "Coolvetica Rg";
    font-size: 6.3vw;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.719px;
    text-transform: lowercase;
  }
  .tapCont {
    flex-direction: column;
    align-items: flex-start;
  }
  .tap {
    color: #fff;
    font-family: "Coolvetica Rg";
    font-size: 15.5vw;
    font-style: normal;
    line-height: 16vw;
    font-weight: 400;
    letter-spacing: 3.3px;
  }
  .rab span {
    background: linear-gradient(
      157deg,
      rgba(255, 255, 255, 0.12) 15.78%,
      rgba(255, 255, 255, 0.72) 43.39%,
      #fff 80.98%,
      rgba(175, 232, 2, 1) 95%
    );
    font-family: "Coolvetica Rg";
    background-clip: text;
    -webkit-background-clip: text;
    font-size: 15.5vw;
    -webkit-text-fill-color: transparent;
    line-height: 12vw;
  }
  .last .priceButton {
    color: #1a1a1a;
    width: 100%;
    text-align: center;
    font-size: 5.3vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.96px;
    text-transform: uppercase;
    margin-top: 8vw;
    overflow: hidden;
  }
  .chemedown {
    margin-top: 7vw;
    margin-left: 23.1vw;
  }
  .rab img {
    width: 16vw;
    margin-top: 0.5vw;
    margin-right: 7vw;
  }
  .lineGr {
    height: 2px;
    width: 81vw;
    background: #98ee02;
    margin: 5vw 0;
  }
  .example {
    color: rgba(255, 255, 255, 0.15);
    font-family: "Coolvetica Rg";
    font-size: 6.6vw;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.99px;
    text-transform: lowercase;
    margin-bottom: 6vw;
  }
  .rightCont ul {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .work {
    background: #161718;
  }
  .rightCont li {
    color: #fff;
    text-align: right;
    font-family: "Gilroy";
    font-size: 3.05vw;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.4px;
    text-align: left;
  }
  .rightCont {
    align-items: flex-start;
    margin-bottom: 5vw;
  }
  .lineTap {
    width: 2px;
    height: 6vw;
    background: rgba(214, 214, 214, 0.16);
    margin: 0 3vw;
  }
  .rightCont > .lineTap {
    background: #98ee02 !important;
    width: 15vw;
    height: 2px;
    margin: 3vw 0;
  }
  .rightCont p {
    color: #fff;
    text-align: right;
    font-family: "Coolvetica Rg";
    font-size: 4.6vw;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.64px;
    text-transform: uppercase;
  }
}
