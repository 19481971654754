.idea p {
  color: #fff;

  text-align: right;
  font-family: "Coolvetica Rg";
  font-size: 2.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.856px;
  text-transform: uppercase;
}
.idea {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.idea img {
  position: absolute;
  width: 8vw;
  top: 0.9vw;
  left: -5vw;
}
.idea span {
  color: #fff;

  font-family: "Gilroy";
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.component .priceButton {
  padding-top: 2vw;
  padding-bottom: 2vw;
}
.component {
  width: 65vw;
  justify-content: space-between;
  margin: 4vw 0;
  margin-bottom: 3vw;
  background: url(./fon.png);
  background-size: cover;
}
@media (max-width: 640px) {
  .Landing {
    overflow-x: hidden;
    width: 100vw;
  }
  .component {
    width: 85vw;
    justify-content: space-between;
    margin: 4vw 0;
    margin-bottom: 3vw;
    background: url(./fon2.png);
    background-size: cover;
    flex-direction: column-reverse;
    padding: 6vw;
    border-radius: 6vw;
    overflow: hidden;
  }
  .idea p {
    color: #fff;
    font-family: "Coolvetica Rg";
    font-size: 8.3vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 7vw;
    margin-bottom: 1vw;
    text-align: center;
    letter-spacing: 0.856px;
    text-transform: uppercase;
  }
  .idea span {
    color: #fff;
    font-family: "Gilroy";
    font-size: 5.3vw;
    font-style: normal;
    text-align: center;
    font-weight: 300;
    line-height: normal;
  }
  .component .priceButton {
    padding-top: 3.5vw !important;
    padding-bottom: 3.5vw !important;
    font-size: 4.5vw;
    border-radius: 12vw;
    margin-top: 5vw;
    text-align: center;
  }
}
