.thanksPage {
  background: url(./thanks.png);
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.thanksPage h1 {
  color: #fff;

  text-shadow: 0px 1px 2px rgba(0, 37, 133, 0.79);
  font-family: "Bicubik";
  font-size: 5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.88px;
  margin-top: 6vw;
}
.pol {
  background: #2b2c2f;
  padding: 1vw;
  box-shadow: 0px 5.5372px 38.76042px 0px rgba(0, 0, 0, 0.25);
  color: #fff;

  font-family: "Montserrat";
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.454px;
  text-transform: uppercase;
  margin-bottom: 3vw;
}
.nextTime {
  color: #fff;
  font-family: "Montserrat";
  font-size: 2.7vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2.4px;
  text-align: center;
}
.nextTime b {
  font-weight: 600;
  color: #b4fe02;
}
.watchWork {
  color: #fff;

  text-align: center;
  font-family: "Montserrat";
  font-size: 1.3vw;
  margin-top: 4vw;
  margin-bottom: 3vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.2px;
}
.thanksPage .askPrice {
  margin: 0;
}
.watchButton {
  display: flex;
  align-items: center;
}
.watchButton img {
  width: 3vw;
  margin: 0 1vw;
}
.stLeft {
  animation: lef 3s linear infinite;
}
.stRight {
  rotate: 180deg;
  animation: rig 3s linear infinite;
}
@keyframes rig {
  0% {
    translate: 0 0;
  }
  50% {
    translate: -5px 0;
  }
  100% {
    translate: 0 0;
  }
}
@keyframes lef {
  0% {
    translate: 0 0;
  }
  50% {
    translate: 5px 0;
  }
  100% {
    translate: 0 0;
  }
}
@keyframes bot {
  0% {
    translate: 0 0;
  }
  50% {
    translate: 0 10px;
  }
  100% {
    translate: 0 0;
  }
}
.thanksPage .posadFooter {
  width: 100%;
  background-color: none;
  background: url(./fon.png);
  background-size: 100%;
  padding-top: 7vw;
  margin-top: -2vw;
}
@media (max-width: 640px) {
  .thanksPage {
    background: url(./fonMob.png);
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .thanksPage .posadFooter {
    width: 100%;
    background-color: none;
    background: url(./fotMob.png);
    background-size: 100%;
    padding-top: 40vw;
    margin-top: -2vw;
  }
  .thanksPage h1 {
    color: #fff;
    text-shadow: 0px 1px 2px rgba(0, 37, 133, 0.79);
    font-family: "Bicubik";
    font-size: 9vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3.88px;
    margin-top: 26vw;
  }
  .pol {
    background: #2b2c2f;
    padding: 3vw;
    box-shadow: 0px 5.5372px 38.76042px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-family: "Montserrat";
    font-size: 4.6vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.454px;
    text-transform: uppercase;
    margin-bottom: 3vw;
    margin-top: 3vw;
  }
  .nextTime {
    color: #fff;
    font-family: "Montserrat";
    font-size: 5.3vw;
    font-style: normal;
    margin: 6vw 0;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2.4px;
    text-align: center;
  }
  .watchWork {
    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 5vw;
    width: 88vw;
    margin-top: 4vw;
    margin-bottom: 8vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.2px;
  }
  .watchButton {
    flex-direction: column;
  }
  .watchButton img {
    width: 14vw;
    margin: 0 1vw;
  }
  .stLeft {
    transform: rotate(90deg);
    animation: bot 3s infinite;
  }
  .thanksPage .askPrice {
    margin-top: 5vw;
    font-family: "Bicubik";
    font-size: 3.3vw;
    background: linear-gradient(
        183deg,
        rgba(255, 255, 255, 0.7) 0.55%,
        rgba(70, 119, 244, 0) 9.38%
      ),
      radial-gradient(
        100% 100% at 50% 0%,
        rgba(255, 255, 255, 0.5) 2.77%,
        rgba(4, 159, 246, 0) 44.59%
      ),
      radial-gradient(
        100% 100% at 50% 0%,
        #49c1ff 0%,
        rgba(83, 124, 204, 0) 72.3%
      ),
      linear-gradient(0deg, #18306c 0%, rgba(40, 54, 129, 0) 75.23%), #4643f1;
    box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.42);
    padding: 7vw 21.2vw;
    color: #fff;
    width: -moz-fit-content;
    width: fit-content;
    letter-spacing: 3px;
    cursor: pointer;
    margin-bottom: 1vw;
    margin-top: 11vw;
  }
  .posadFooterFirst {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  .logosFooter {
    width: 60vw;
  }
  .posadFooterFirst > p {
    color: #fff;
    font-family: "Montserrat";
    font-size: 5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 0;
    margin: 8vw 0;
  }
  .posadFooterFirst > p a {
    color: #fff;
    font-family: "Montserrat";
    font-size: 6vw;
    font-style: normal;
    text-decoration: none;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  .posadFooter > div {
    width: 94vw;
    margin: 0vw auto;
  }
  .posadFooterFirst > span {
    color: #fff;
    font-family: "Montserrat";
    font-size: 5vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 0;
  }
  .msF {
    width: 10.8vw;
    margin-top: 4vw;
  }
  .posadFooterSecond {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 5vw;
  }
  .posadFooterSecond a {
    color: rgba(255, 255, 255, 0.4);
    font-family: "Montserrat";
    font-size: 3.7vw;
    text-decoration: none;
    margin-left: 2vw;
    margin-top: 1vw;
    font-style: normal;
    font-weight: 400;
    margin: 3vw;
    line-height: normal;
  }
}
