.landHeader {
  background: url(../Landing/fon.png);
  padding-top: 1.5vw;
  background-color: #161718;
  background-size: cover;
  background-position-x: -2.5vw;
}
.landHeaderBody {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  width: 65vw;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}
.rightLand {
  margin-left: -3.5vw;
}
.landHeaderBody > div {
  display: flex;
  align-items: center;
  padding: 1vw;
  background: #212123;
  font-size: 0.65vw;
  border-radius: 1vw;
}
.landHeaderBody > div img {
  width: 1.5vw;
  margin: 0 0.2vw;
  display: block;
}
.leftHead > div {
  padding: 0.3vw;
}
.leftHead h3 {
  color: #fff;
  border-radius: 0.6vw;
  border: 2px solid #9aec02;
  box-shadow: 0px 0px 13px 0px rgba(152, 238, 2, 0.55);
  padding: 0.5vw;
  width: fit-content;
  font-weight: 500;
  font-size: 0.8vw;
}
.graf {
  position: relative;
}
.graf p {
  color: #3a3a3a;

  font-family: "Coolvetica Rg";
  font-size: 3.1vw;
  font-style: normal;
  font-weight: 600;

  letter-spacing: 1.206px;
  text-transform: uppercase;
}
.aboutAudition {
  width: 65vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.videoRaz {
  border-radius: 2.3vw;
  border: 2px solid #cecece;
  padding: 1vw 2vw;
  background: #f4f9fd;
}
.videoRaz p {
  color: #3a3a3a;
  font-family: "Gilroy";
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 300;

  letter-spacing: 0.609px;
}
.videoRaz {
  display: flex;
  align-items: center;
}
.videoRaz span {
  color: #3a3a3a;
  margin-left: 1vw;
  font-family: "Coolvetica Rg";
  font-size: 4.2vw;
  font-style: normal;
  font-weight: 400;
  display: flex;
  letter-spacing: 1.625px;
  text-transform: uppercase;
  align-items: flex-end;
}
.component {
  background-image: radial-gradient(
      191.48% 546.97% at -12.01% 169.72%,
      rgba(255, 255, 255, 0.2) 8.55%,
      rgba(69, 69, 69, 0) 100%
    ),
    radial-gradient(
      365.22% 190.65% at 102.68% 83.33%,
      rgba(255, 255, 255, 0.2) 8.55%,
      rgba(69, 69, 69, 0) 100%
    ),
    #161718;
  filter: drop-shadow(0px 8.800000190734863px 33px rgba(38, 38, 38, 0.12))
    drop-shadow(0px 0px 9.90000057220459px rgba(50, 50, 50, 0.24));
  padding: 1.5vw 2.5vw;
  display: flex;
  align-items: center;
  background-color: rgba(22, 23, 24, 1);
  border-radius: 2vw 2vw 4vw 4vw;
}
.videoRaz span pre {
  color: #3a3a3a;
  font-family: "Coolvetica Rg";
  font-size: 2.4vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.914px;
  text-transform: uppercase;
  transform: translateY(-0.5vw);
}
.videoRaz p b {
  color: #3a3a3a;

  font-family: "Coolvetica Rg";
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.609px;
  text-transform: uppercase;
}
.graf img {
  position: absolute;
  bottom: 0.5vw;
  right: -5.5vw;
  width: 9.5vw;
}
.graf span {
  color: #735bff;
  font-family: "Coolvetica Rg";
  font-size: 2.4vw;
  font-style: normal;
  font-weight: 600;

  letter-spacing: 0.947px;
  text-transform: uppercase;
}
.leftHead h3 span {
  color: #9aec02;
}
.landHeaderModel {
  display: flex;
  margin: 0 auto;
  width: 65vw;
  justify-content: space-between;
  margin-top: 2vw;
}
.landHeaderBody > div p {
  margin-right: 0.5vw;
  font-family: "Gilroy";
}
.landHeaderBody > div a {
  text-decoration: none;
  color: #fff;
  font-size: 1.2vw;
  margin-left: 0.5vw;
  font-weight: 600;
  font-family: "Coolvetica Rg";
  letter-spacing: 0.497px;
}
.landHeaderBody > img {
  width: 10.5vw;
}
.mainLine {
  width: 65vw !important;
  margin: 0 auto;
  margin-top: 1vw !important;
}
.landHeaderBody > p {
  font-size: 0.65vw;
  margin-left: 1vw;
  font-family: "Gilroy";
}
.landHeaderBody > p span {
  font-weight: 600;
  color: #adff00;
}
.headLine {
  width: 14.5vw;
  height: 1.5vw;
  display: block;
  margin: 0 1vw;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.leftHead h1,
.leftHead h1 span {
  font-family: "Coolvetica Rg";
  font-size: 8vw;
}
.leftHead h1 {
  line-height: 90%;
  color: #fff;
  margin-top: 1.5vw;
  margin-bottom: 4vw;
  letter-spacing: 2.8px;
}
.twoAchiev {
  display: flex;
  position: relative;
}
.twoAchiev > img {
  position: absolute;
  right: 5.2vw;
  top: 5vw;
  width: 2.2vw;
}
.achItem {
  width: 6vw;
  height: 6vw;
  padding: 0.5vw;
  border-radius: 1.3vw;
  background: #212123;

  box-shadow: 0px 3.93195px 44.23449px 0px rgba(0, 0, 0, 0.25);
}
.priceLand {
  display: flex;
  align-items: center;
  color: #fff;
  margin-top: 4vw;
  margin-bottom: 8vw;
}
#modelV {
  width: 30vw;
  height: 40vw;
  position: relative;
  z-index: 2;
  overflow: hidden;
  margin-top: -3vw;
}
.arrow {
  margin-top: -9vw;
  translate: 0 -2vw;
  margin-left: 6vw;
  width: 20vw;
  position: relative;
}
.grad {
  background: linear-gradient(
    162deg,
    rgba(255, 255, 255, 0.31) 25.42%,
    rgba(255, 255, 255, 0.72) 38.13%,
    #fff 60.68%,
    rgba(175, 232, 2, 1) 99%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.achBody img {
  width: 80%;
}
.priceButton {
  cursor: pointer;
  background: radial-gradient(
      55.13% 58.11% at 91.03% 19.99%,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      120.16% 100.06% at 74.25% 0%,
      rgba(255, 214, 0, 0.56) 7.29%,
      rgba(146, 158, 5, 0) 100%
    ),
    linear-gradient(90deg, #bdff00 41.36%, #80ff00 102.58%), #d9d9d9;
  filter: drop-shadow(
      0px 7.886188507080078px 29.573205947875977px rgba(189, 255, 0, 0.12)
    )
    drop-shadow(0px 0px 8.87196159362793px rgba(167, 232, 2, 0.57));
  padding: 1.8vw 3.5vw;
  color: #1a1a1a;
  font-family: "Gilroy";
  position: relative;
  overflow-x: hidden;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14vw;
  text-transform: uppercase;
  border-radius: 6vw;
}
.an img {
  width: 40vw;
}
.sss {
  transition: 0.5s;
  transform-origin: center;
}
.get {
  animation: get 7s infinite;
  transform-origin: center;
}
@keyframes get {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.priceLandText div {
  display: flex;
  align-items: center;
}
.achText {
  color: #fff;
  font-size: 3.1vw;
  font-family: "Coolvetica Rg";
  margin: 0 1vw;
}
.priceLandText {
  margin-left: 2vw;
}
.priceLandText svg {
  margin-right: 0.5vw;
  margin-left: 1vw;
}
.priceLandText > p {
  font-size: 1.1vw;
  font-family: "Gilroy";
}
.priceLandText div p {
  font-weight: 600;
  font-size: 1.5vw;
  font-family: "Gilroy";
}
.achText p {
  font-family: "Coolvetica Rg";
}
.achText article {
  font-size: 1vw;
  text-transform: uppercase;
  color: #fff;

  text-align: right;
  font-family: "Gilroy";

  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 3.318px;
  text-transform: lowercase;
}
.achText span {
  color: #b0e802;
  font-size: 2.5vw;
  font-weight: 500;
  margin-right: 0.3vw;
}
.achBody {
  background: linear-gradient(180deg, #626262 0%, #3a3a3a 100%);
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 1vw;
  box-shadow: 0px 3.93195px 44.23449px 0px rgba(0, 0, 0, 0.25);
}
.check {
  display: flex;
  align-items: flex-start;
}
.leftHead h1 div {
  display: flex;
}
.brandingPromo {
  font-family: "Gilroy" !important;
  font-size: 2.2vw !important;
  line-height: 120% !important;
  letter-spacing: 5.006px;
  color: #454545;
  margin-right: 1vw;
}
.secondAboutContent {
  width: 65vw;
  margin: 0 auto;
  margin-top: 7vw;
}
.secondLeftAbout > p {
  color: #735bff;

  font-family: "Coolvetica Rg";
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 600;

  letter-spacing: 0.72px;
  text-transform: lowercase;
}
.about {
  background: url(./fon2.png);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-position-x: -2.5vw;
  background-size: cover;
}
.aboutContent {
  display: flex;
  align-items: flex-start;
  width: 65vw;
  justify-content: space-between;
  padding-top: 3vw;
}
.topAbout {
  display: flex;
  align-items: center;
  position: relative;
}
.krug {
  position: absolute;
  top: -1.5vw;
  left: 28vw;
  width: 5vw !important;
  animation: krug 15s linear infinite;
}

.flare {
  position: absolute;
  top: 0;
  height: 100%;
  width: 45px;
  transform: skewX(-45deg);
  animation: flareAnimation;
  left: -150%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.4)
  );
  animation: flareAnimation 3s infinite linear;
}

@keyframes flareAnimation {
  0% {
    left: -150%;
  }
  100% {
    left: 150%;
  }
}
@keyframes krug {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}
.topAbout img {
  width: 8.1vw;
  border-radius: 1vw;
}
.about h2 {
  color: #151924;
  font-family: "Coolvetica Rg" !important;
  font-size: 5vw;
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  letter-spacing: 1.975px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 100%;
  margin-top: 1vw;
  margin-bottom: 1vw;
}
.aboutSecondLine {
  background: #735bff;
  height: 3px;
  width: 6.5vw;
}
.tvorch {
  display: flex;
  flex-direction: column;
  color: #151924;

  font-family: "Coolvetica Rg";
  font-size: 2.6vw;
  font-style: normal;
  font-weight: 700;

  letter-spacing: 1.04px;
  text-transform: uppercase;
  margin-top: 1vw;
}
.tvorch span {
  display: flex;
  align-items: center;
  color: #735bff;

  font-size: 1.7vw;
  font-style: normal;
  font-weight: 700;

  letter-spacing: 0.691px;
  text-transform: uppercase;
}
.tvorch span svg {
  margin-top: 0.3vw;
  margin-left: 0.5vw;
}
.tvorch span div {
  display: flex;
  align-items: center;
}
.aboutLine {
  height: 3px;
  width: 100%;
  background: #cfdde8;
  margin: 3vw 0;
  border-radius: 8vw;
  overflow: hidden;
}
.leftAbout > p {
  color: #151924;

  font-family: "Gilroy";
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
h2 img {
  width: 4vw;
  margin-left: 1vw;
  display: block;
}
.about h2 span {
  color: #735bff;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.topAbout p {
  color: rgba(49, 44, 103, 0.15);
  margin-top: -0.6vw;
  font-family: "Coolvetica Rg";
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.72px;
  text-transform: lowercase;
  margin-left: 1vw;
}
.earth {
  box-shadow: 0px 4.07333px 73.32px 0px rgba(144, 138, 177, 0.24);
  border-radius: 50%;
  width: 32.5vw;
}
.secondLeftAbout h3 {
  color: #3a3a3a;

  font-family: "Coolvetica Rg";
  font-size: 7vw;
  font-style: normal;
  font-weight: 700;
  margin-top: 0.5vw;
  line-height: normal;
  letter-spacing: 2.82px;
}
.secondLeftAbout h3 span {
  display: flex;
  align-items: flex-start;
  line-height: 6.8vw;
  font-family: "Coolvetica Rg";
}
.secondLeftAbout h3 img {
  width: 2vw;
  margin-top: 1.2vw;
  margin-left: 0.7vw;
}
.ulsSecondAbout {
  display: flex;
  margin-top: 2.5vw;
}
.secondAboutContent {
  display: flex;
  justify-content: space-between;
}
.ulsSecondAbout ul {
  list-style-type: none;
  margin-right: 1.5vw;
}
.ulsSecondAbout li {
  display: flex;
  align-items: center;
  padding: 0.5vw 0;
  border-bottom: 1px solid #d6d6d6;
}
.ulsSecondAbout li p {
  color: #3a3a3a;

  text-align: center;
  font-family: "Gilroy";
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ulsSecondAbout li img {
  margin-right: 1vw;
  width: 2.5vw;
}
.gradT {
  background: linear-gradient(
    164deg,
    rgba(58, 58, 58, 1) 68.13%,
    rgba(175, 232, 2, 1) 100.42%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Coolvetica Rg";
}
.greenLine {
  width: 6.3vw;
  margin-top: 1.5vw;
  height: 3px;
  background: #98ee02;
  margin-right: 3.7vw;
  margin-left: 0.1vw;
}
.secondAboutRight > img {
  margin-top: 3vw;
  width: 28vw;
  margin-right: -2vw;
}
.secondAboutRight {
  position: relative;
}
.cub {
  position: absolute;
  top: 0;
  left: -12vw;
  animation: cubies 8s infinite;
  z-index: 5;
}
.cub img {
  width: 27vw;
}
@keyframes cubies {
  0% {
    scale: 1;
  }
  25% {
    scale: 1.05 1;
    rotate: -3deg;
    translate: 0 -10px;
  }
  50% {
    scale: 1;
    rotate: 0deg;
    translate: 0px;
  }
  75% {
    scale: 1.05 1;
    rotate: 3deg;
    translate: 0 -10px;
  }
  100% {
    scale: 1;
    rotate: 0deg;
  }
}
@media screen and (max-width: 640px) {
  .aboutContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 94vw;
    justify-content: space-between;
    padding-top: 9vw;
  }
  .krug {
    display: none;
  }
  .topAbout img {
    width: 26.1vw;
    border-radius: 1vw;
  }
  .topAbout p {
    color: rgba(49, 44, 103, 0.15);
    margin-top: -1.6vw;
    font-family: "Coolvetica Rg";
    font-size: 6.8vw;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.72px;
    text-transform: lowercase;
    margin-left: 5vw;
  }
  .about h2 {
    color: #151924;
    font-family: "Coolvetica Rg" !important;
    font-size: 18vw;
    font-style: normal;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    letter-spacing: 1.975px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 100%;
    margin-top: 7vw;
    margin-bottom: 1vw;
  }
  .about h2 span {
    color: #735bff;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  h2 img {
    width: 14vw;
    margin-left: 4vw;
    display: block;
  }
  .leftAbout > p {
    color: #151924;
    font-family: "Gilroy";
    font-size: 5.8vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .aboutLine {
    height: 3px;
    width: 100%;
    background: #cfdde8;
    margin: 8vw 0;
    border-radius: 8vw;
    overflow: hidden;
  }
  .aboutSecondLine {
    background: #735bff;
    height: 3px;
    width: 25.5vw;
  }
  .tvorch {
    display: flex;
    flex-direction: column;
    color: #151924;
    font-family: "Coolvetica Rg";
    font-size: 9.6vw;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 1.04px;
    text-transform: uppercase;
    margin-top: 1vw;
  }
  .tvorch span {
    display: flex;
    align-items: center;
    color: #735bff;
    font-size: 6vw;
    font-style: normal;
    margin-top: -1vw;
    line-height: 5vw;
    font-weight: 500;
    letter-spacing: 0.691px;
    text-transform: uppercase;
  }
  .earth {
    box-shadow: 0px 4.07333px 73.32px 0px rgba(144, 138, 177, 0.24);
    border-radius: 50%;
    width: 94vw;
    margin-top: 12vw;
  }
  .secondAboutContent {
    width: 94vw;
    margin: 0 auto;
    flex-direction: column;
    margin-top: 7vw;
  }
  .secondLeftAbout > p {
    color: #735bff;
    font-family: "Coolvetica Rg";
    font-size: 4.7vw;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.72px;
    text-transform: lowercase;
  }
  .secondLeftAbout h3 {
    color: #3a3a3a;
    font-family: "Coolvetica Rg";
    font-size: 18vw;
    font-style: normal;
    font-weight: 500;
    margin-top: 0.5vw;
    /* line-height: normal; */
    letter-spacing: 2.82px;
  }
  .secondLeftAbout h3 span {
    display: flex;
    align-items: flex-start;
    line-height: 16vw;
    font-family: "Coolvetica Rg";
  }
  .greenLine {
    width: 17.3vw;
    margin-top: 4.5vw;
    height: 2px;
    background: #98ee02;
    margin-right: 9.7vw;
    margin-left: 0.1vw;
  }
  .ulsSecondAbout {
    display: flex;
    flex-direction: column;
    margin-top: 8.5vw;
  }
  .secondLeftAbout h3 img {
    width: 5vw;
    margin-top: 1.2vw;
    margin-left: 1.7vw;
  }
  .ulsSecondAbout li img {
    margin-right: 4vw;
    width: 9.5vw;
  }
  .ulsSecondAbout li p {
    color: #3a3a3a;
    text-align: center;
    font-family: "Gilroy";
    font-size: 5.1vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .ulsSecondAbout li {
    display: flex;
    align-items: center;
    padding: 3.5vw 0;
    border-bottom: 1px solid #d6d6d6;
  }
  .secondAboutRight > img {
    margin-top: 3vw;
    width: 96vw;
    margin-right: 0vw;
  }
  .cub {
    position: absolute;
    top: 0;
    left: -17vw;
    animation: cubies 8s infinite;
    z-index: 5;
  }
  .secondAboutRight > img {
    margin-top: 26vw;
    width: 96vw;
    margin-right: 0vw;
  }
  .cub img {
    width: 94vw;
  }
  .mainLine {
    width: 94vw !important;
    margin: 0 auto;
    margin-top: 1vw !important;
  }
  .aboutAudition {
    width: 94vw;
    margin-top: 12vw;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }
  .graf p {
    color: #3a3a3a;
    font-family: "Coolvetica Rg";
    font-size: 13.1vw;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 1.206px;
    text-transform: uppercase;
  }
  .graf span {
    color: #735bff;
    font-family: "Coolvetica Rg";
    font-size: 10.4vw;
    font-style: normal;
    font-weight: 500;
    line-height: 10vw;
    letter-spacing: 0.947px;
    text-transform: uppercase;
  }
  .graf img {
    position: absolute;
    bottom: 0.5vw;
    right: -12.5vw;
    width: 28.5vw;
  }
  .about {
    background: #f4f9fd;
    background-size: cover;
  }
  .messs img {
    margin-left: 1.5vw;
    margin-top: 2.5vw;
    width: 6.5vw;
  }
  .videoRaz p b {
    color: #3a3a3a;
    font-family: "Coolvetica Rg";
    font-size: 7.6vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.609px;
    text-transform: uppercase;
  }
  .videoRaz span {
    color: #3a3a3a;
    margin-left: 1vw;
    font-family: "Coolvetica Rg";
    font-size: 17.2vw;
    font-style: normal;
    position: absolute;
    right: 4vw;
    top: 4.5vw;
    font-weight: 400;
    display: flex;
    letter-spacing: 1.625px;
    text-transform: uppercase;
    align-items: flex-end;
  }
  .videoRaz span pre {
    color: #3a3a3a;
    font-family: "Coolvetica Rg";
    font-size: 9.4vw;
    font-style: normal;
    margin-top: -1vw;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.914px;
    text-transform: uppercase;
    transform: translateY(-2.5vw);
  }
  .videoRaz {
    border-radius: 8.3vw;
    border: 2px solid #cecece;
    padding: 5vw 5vw;
    background: #f4f9fd;
    margin: 7vw auto;
    width: 90vw;
    position: relative;
  }
  h1 span svg {
    margin-left: 0vw;
    width: 4vw;
    height: 4vw;
  }
  .noDemp > img {
    width: 94vw !important;
    margin-right: 3vw;
    filter: drop-shadow(0px 0px 41.84782px 0px rgba(131, 123, 180, 0.42));
  }
  .dalp {
    color: rgba(49, 44, 103, 0.15);
    display: flex;
    align-items: center;
    font-family: "Coolvetica Rg";
    font-size: 6.1vw !important;
    font-style: normal;
    font-weight: 400;
    margin-right: 0.5vw;
    letter-spacing: 0.82px;
    text-transform: lowercase;
    margin-top: 1vw;
  }
  .dalp img {
    margin-top: 0.5vw;
    width: 11vw !important;
  }
  .leftHead h3 {
    color: #fff;
    border-radius: 3.6vw;
    border: 2px solid #9aec02;
    box-shadow: 0px 0px 13px 0px rgba(152, 238, 2, 0.55);
    padding: 2vw 2.5vw;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 500;
    font-size: 2.8vw;
    margin-bottom: 5vw;
  }
  .leftHead h1,
  .leftHead h1 span {
    font-family: "Coolvetica Rg";
    font-size: 20vw;
    font-weight: 500;
  }
  .brandingPromo {
    font-family: "Gilroy" !important;
    font-size: 4.2vw !important;
    line-height: 120% !important;
    letter-spacing: 3.006px;
    color: #454545;
    margin-top: 1vw;
    font-weight: 800;
    margin-right: 2vw;
  }
  .landHeaderBody > img {
    width: 40vw;
  }

  .landHeaderBody {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    width: 94vw;
    margin: 0vw auto;
    padding: 5vw 0;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    position: relative;
    z-index: 10;
  }
  .landHeaderBody > div img {
    width: 6.5vw;
    margin: 0 1vw;
  }
  .landHeaderBody > div {
    display: flex;
    align-items: center;
    padding: 2vw;
    background: none;
    font-size: 2.5vw;
    border-radius: 2vw;
  }
  .headLine {
    display: none;
  }
  .landHeaderBody > p {
    font-size: 0.65vw;
    margin-left: 1vw;
    font-family: "Gilroy";
    display: none;
  }
  .landHeaderBody > div > p {
    margin-right: 0.5vw;
    font-family: "Gilroy";
    display: none;
  }
  .landHeaderBody > div a p {
    font-size: 2.2vw;
    text-align: right;
    font-weight: 300;
  }
  .landHeaderBody > div a {
    text-decoration: none;
    color: #fff;
    font-size: 4.2vw;
    margin-left: 0.5vw;
    font-weight: 600;
    font-family: "Coolvetica Rg";
    letter-spacing: 0.497px;
  }
  .priceButton {
    cursor: pointer;
    background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.4) 42.89%,
        rgba(255, 255, 255, 0) 43.04%
      ),
      radial-gradient(
        55.13% 58.11% at 91.03% 19.99%,
        rgba(255, 255, 255, 0.7) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(
        120.16% 100.06% at 74.25% 0%,
        rgba(255, 214, 0, 0.56) 7.29%,
        rgba(146, 158, 5, 0) 100%
      ),
      linear-gradient(90deg, #bdff00 41.36%, #80ff00 102.58%), #d9d9d9;
    filter: drop-shadow(
        0px 7.886188507080078px 29.573205947875977px rgba(189, 255, 0, 0.12)
      )
      drop-shadow(0px 0px 8.87196159362793px rgba(167, 232, 2, 0.57));
    padding: 6.8vw 14.5vw;
    color: #1a1a1a;
    font-family: "Gilroy";
    position: relative;
    overflow-x: hidden;
    font-size: 4.5vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2.727px;
    text-transform: uppercase;
    border-radius: 12vw;
  }
  .priceLandText div p {
    font-weight: 500;
    font-size: 5.5vw;
    font-family: "Gilroy";
  }
  .klik {
    position: absolute;
    top: 140vw;
    width: 20vw;
    left: 47vw;
    animation: sc 3s infinite;
  }
  @keyframes sc {
    0% {
      scale: 1;
    }
    50% {
      scale: 1.1;
    }
    100% {
      scale: 1;
    }
  }
  .priceLandText > p {
    font-size: 5vw;
    font-family: "Gilroy";
    font-weight: 400;
    margin-bottom: 4vw;
  }
  .priceLand {
    display: flex;
    align-items: center;
    color: #fff;
    flex-direction: column-reverse;
    margin-top: 4vw;
    margin-bottom: 8vw;
  }
  .priceLandText div p {
    font-weight: 500;
    font-size: 7.2vw;
    font-family: "Gilroy";
  }
  .landHeader {
    padding-bottom: 5vw;
  }
  .priceLandText {
    margin-left: 0vw;
    margin-top: 5vw;
  }
  .priceLandText div {
    justify-content: center;
  }
  .landHeaderModel {
    display: flex;
    margin: 0 auto;
    width: 94vw;
    justify-content: space-between;
    margin-top: 11vw;
    flex-direction: column;
  }
  .razdelit {
    display: block;
    margin-top: -0.3vw;
  }
  .about {
    padding-bottom: 5vw;
    margin-top: -0.1vw;
  }
  .rightDemp .videoRaz p b {
    color: #3a3a3a;
    font-family: "Coolvetica Rg";
    font-size: 4vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.609px;
    text-transform: uppercase;
  }

  .videoRaz p {
    color: #3a3a3a;
    font-family: "Gilroy";
    font-size: 4vw;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.609px;
  }
  .landHeader {
    background: rgba(22, 23, 24, 1);
  }
  .achItem {
    width: 17vw;
    height: 17vw;
    padding: 1.5vw;
    border-radius: 3.3vw;
    background: #212123;
    box-shadow: 0px 3.93195px 44.23449px 0px rgba(0, 0, 0, 0.25);
  }
  .achBody {
    background: linear-gradient(180deg, #626262 0%, #3a3a3a 100%);
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 3vw;
    box-shadow: 0px 3.93195px 44.23449px 0px rgba(0, 0, 0, 0.25);
  }
  .achText {
    color: #fff;
    font-size: 10.1vw;
    font-family: "Coolvetica Rg";
    margin: 0 2vw;
    margin-right: 4vw;
  }
  .achText article {
    font-size: 3vw;
    text-transform: uppercase;
    color: #fff;
    text-align: right;
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 2.318px;
    text-transform: lowercase;
  }
  .banana {
    width: 75vw;
    margin: 0 auto;
    display: block;
    margin-bottom: 10vw;
  }
  .rightLand {
    width: 100vw;
    margin-left: 0;
    position: fixed;
    z-index: 222;
    left: 0;
    top: 0;
    background: #161718;
    height: 100vh;
  }
  .achText span {
    color: #b0e802;
    font-size: 7.5vw;
    font-weight: 500;
    margin-right: 0.3vw;
  }
  #modelV {
    width: 100vw;
    height: 140vw;
    position: relative;
    z-index: 2;
    overflow: hidden;
    margin-top: -3vw;
  }
  .krest {
    width: 15vw;
    float: right;
    margin-top: 1vw;
    margin-right: 1vw;
  }
  .arrow {
    margin-top: -27vw;
    translate: 0 -2vw;
    margin-left: 13vw;
    width: 75vw;
    position: relative;
  }
}
