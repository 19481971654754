@font-face {
    font-family: 'Bicubik';
    src: url('Bicubik.eot');
    src: local('Bicubik'),
        url('Bicubik.eot?#iefix') format('embedded-opentype'),
        url('Bicubik.woff2') format('woff2'),
        url('Bicubik.woff') format('woff'),
        url('Bicubik.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

