.footer_left {
  display: flex;
  flex-direction: column;
  margin-right: 4vw;
}
.footer_left > img {
  width: 12vw;
  margin-bottom: 0.5vw;
}
.footer_content {
  display: flex;
  justify-content: space-between;
}
.footer_left a {
  text-decoration: none;
}
.footer {
  background: #f4f9fd;
  padding: 3vw;
}
.messs {
  display: flex;
  align-items: center;
  color: #3a3a3a;

  font-family: "Coolvetica Rg";
  font-size: 1.05vw;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.4px;
  margin-bottom: 1.5vw;
}
.messs img {
  margin-left: 0.5vw;
  margin-top: 0.5vw;
  width: 1.5vw;
}
.worker {
  color: #3a3a3a;

  font-family: "Gilroy";
  font-size: 0.7vw;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 1.5vw;
  letter-spacing: 0.28px;
}
.rass {
  color: #3a3a3a;

  font-family: "Gilroy";
  font-size: 0.7vw;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 1vw;
  letter-spacing: 0.28px;
}
.emails {
  color: #3a3a3a;

  font-family: "Coolvetica Rg";
  font-size: 1.05vw;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.4px;
  margin-bottom: 1.5vw;
}
.telik {
  color: #735bff;

  font-family: "Coolvetica Rg";
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.72px;
  text-transform: lowercase;
}
.adress {
  color: #3a3a3a;

  font-family: "Gilroy";
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 500;

  letter-spacing: 0.32px;
  text-transform: lowercase;
  margin-bottom: 2vw;
}
.footer_content {
  width: 65vw;
  margin: 0 auto;
}
@media (max-width: 640px) {
  .footer_content {
    flex-direction: column;
    width: 94vw;
    align-items: center;
  }
  .footer_left > img {
    width: 55vw;
    margin-bottom: 4.5vw;
    margin-top: 5vw;
  }
  .adress {
    color: #3a3a3a;
    font-family: "Gilroy";
    font-size: 4.5vw;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.32px;
    text-transform: lowercase;
    margin-bottom: 5vw;
  }
  .messs {
    display: flex;
    align-items: center;
    color: #3a3a3a;
    font-family: "Coolvetica Rg";
    font-size: 6.05vw;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.4px;
    margin-bottom: 1.5vw;
  }
  .rass {
    color: #3a3a3a;
    font-family: "Gilroy";
    font-size: 3.7vw;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 4vw;
    letter-spacing: 0.28px;
  }
  .footer_right > div > div {
    width: 94vw !important;
    margin-top: 5vw;
    height: 70vw !important;
  }
  .telik {
    color: #735bff;
    font-family: "Coolvetica Rg";
    font-size: 9.8vw;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.72px;
    text-transform: lowercase;
  }
  .worker {
    color: #3a3a3a;
    font-family: "Gilroy";
    font-size: 4.7vw;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 7.5vw;
    letter-spacing: 0.28px;
  }
  .emails {
    color: #3a3a3a;
    font-family: "Coolvetica Rg";
    font-size: 6.05vw;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    letter-spacing: 0.4px;
    margin-bottom: 6.5vw;
  }
  .messs img {
    margin: 0 2.5vw;
    margin-top: 0.5vw;
    width: 10.5vw;
  }
  .footer_left {
    align-items: center;
  }
  .messs {
    flex-direction: column;
  }
  .messs div {
    margin-top: 4vw;
  }
}
