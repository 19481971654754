.g {
  font-family: "Bicubik";
}
.main {
  justify-content: center;
}
.logosTable img {
  width: 6.2vw;
  background: rgb(29, 29, 29);
  height: 101%;
}
.logosTable {
  display: grid;
  grid-template-columns: 6.2vw 6.2vw 6.2vw;
}
.typesCont {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2vw;
}
.askPrice {
  margin-top: 5vw;
  font-family: "Bicubik";
  font-size: 1.3vw;
  background: linear-gradient(
      183deg,
      rgba(255, 255, 255, 0.7) 0.55%,
      rgba(70, 119, 244, 0) 9.38%
    ),
    radial-gradient(
      100% 100% at 50% 0%,
      rgba(255, 255, 255, 0.5) 2.77%,
      rgba(4, 159, 246, 0) 44.59%
    ),
    radial-gradient(
      100% 100% at 50% 0%,
      #49c1ff 0%,
      rgba(83, 124, 204, 0) 72.3%
    ),
    linear-gradient(0deg, #18306c 0%, rgba(40, 54, 129, 0) 75.23%), #4643f1;

  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.42);
  padding: 2vw 4.2vw;
  color: #fff;
  width: fit-content;
  letter-spacing: 3px;
  cursor: pointer;
  margin-bottom: 1vw;
}
.butSpan div {
  width: 2vw;
  height: 0.2vw;
  background: #a2fe04;
  margin-right: 0.8vw;
}
.butSpan {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 0.85vw;
  margin-bottom: 8vw;
}
.scroll {
  position: absolute;
  width: 7vw;

  top: 45vw;
  left: 50%;
  translate: -50% 0;
  z-index: 22;
}
.leftFon {
  position: absolute;
  left: 0;
  top: 25vw;
  width: 9vw;
  animation: right 8s infinite;
}
.video {
  position: absolute;
  top: 10vw;
  left: 3vw;
  animation: right 7s infinite;
}
.rightFon {
  position: absolute;
  top: 10vw;
  right: 0;
  width: 14vw;
  animation: right 9s infinite;
}
h1 span svg {
  margin-left: 0vw;
  width: 4vw;
}
.kek1 {
  animation: left 4s infinite;
}
.kek2 {
  animation: left 6s infinite;
}
.kek3 {
  animation: left 8s infinite;
}
@keyframes right {
  0% {
    transform: scale(1) translateY(0);
  }
  50% {
    transform: scale(1.1) translateY(-30px);
  }
  100% {
    transform: scale(1) translateY(0);
  }
}
@keyframes left {
  0% {
    transform: scale(1) translateY(0);
  }
  50% {
    transform: scale(1) translateY(-7px);
  }
  100% {
    transform: scale(1) translateY(0);
  }
}
.askPrice {
  overflow: hidden;
}
.posContent h2 {
  color: #1d1d1d;

  text-align: center;
  font-family: "Bicubik";
  font-size: 5.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 120px */
  letter-spacing: 0.5vw;
}
.scroll img {
  width: 100%;
}
.button-main {
  font-family: "Bicubik";
  font-size: 0.55vw;
  background: radial-gradient(
      100% 100% at 50% 0%,
      rgba(255, 255, 255, 0.5) 2.77%,
      rgba(4, 159, 246, 0) 44.59%
    ),
    radial-gradient(
      100% 100% at 50% 0%,
      #49c1ff 0%,
      rgba(83, 124, 204, 0) 72.3%
    ),
    linear-gradient(0deg, #18306c 0%, rgba(40, 54, 129, 0) 75.23%),
    linear-gradient(
      90deg,
      rgba(48, 218, 255, 0.68) 0%,
      rgba(70, 178, 238, 0) 60.26%
    ),
    linear-gradient(270deg, #6846ee 0.18%, rgba(70, 74, 238, 0) 60.65%), #4643f1;

  box-shadow: 0px 3.35962px 17.63799px 0px rgba(0, 0, 0, 0.42);
  padding: 1vw 2vw;
  color: #fff;
  width: fit-content;
  letter-spacing: 1.3px;
  cursor: pointer;
}
.mess {
  background: rgba(49, 58, 73, 0.69);
  transition: 0.5s;
  box-shadow: 0px 3.35962px 23.51732px 0px rgba(0, 0, 0, 0.25);
  padding: 0.5vw 1vw;
  padding-left: 1.5vw;
  font-size: 0.7vw;
  margin: 0 0.5vw;
  cursor: pointer;
}
.headerContent {
  width: 65vw;
  display: flex;
  justify-content: space-between;
}
.posContent {
  background: url(./fon2.png);
  background-size: cover;
  background-position: center 0;
  padding-top: 10vw;
  position: relative;
}
.blub,
.handPos {
  position: absolute;
}
.blub {
  right: 0;
  top: 14vw;
  width: 16vw;
}
.handPos {
  top: 11vw;
  left: -2vw;
  width: 24vw;
  animation: hand 4s infinite;
}
@keyframes hand {
  0% {
    translate: 0 0;
  }
  50% {
    translate: 20px 0;
  }
  100% {
    translate: 0 0;
  }
}
.typeContLine {
  margin: 1vw 0;
  width: 100%;
  height: 2px;
  background: rgba(243, 243, 243, 1);
}
.typeCont {
  padding: 1.5vw;
  background: #fff;
  margin: 0 1vw;
  box-shadow: 0px 4px 50px 0px rgba(105, 100, 164, 0.4);
}
.typeCont > p {
  color: #2d2d2d;
  font-family: "Bicubik";
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.56px;
  text-transform: uppercase;
}
.typeCont li {
  display: flex;
  align-items: center;
  padding: 0.5vw 0;
  color: #000;

  font-family: "Montserrat";
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
}
.zadaccha {
  margin: 3vw auto;
  width: 47vw;
  display: flex;
  align-items: center;
}

.zadaccha img {
  width: 7vw;
  margin-right: 1vw;
}
.zadaccha p {
  background: #fff;
  padding: 0.9vw 2vw;
  box-shadow: 0px 4px 35px 0px rgba(39, 56, 87, 0.15);
  color: #2d2d2d;

  font-family: "Montserrat";
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.379px;
  text-transform: uppercase;
}
.proj {
  display: flex;
  width: 65vw;
  margin: 0 auto;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 6vw;
}
.posadFooter {
  background: #191919;
  background-size: 100%;

  padding: 1.5vw 0;
  background-repeat: no-repeat;
}
.rightContent {
  position: relative;
}
.rightContent .cl {
  position: absolute;
  top: 1.3vw;
  width: 4.15vw;
  left: -2.5vw;
}
.mess:hover {
  background: rgba(49, 58, 73, 0.2);
}
.sk {
  color: #a2ff00;
}
.st {
  color: #495bff;
}
.rightContent .prem {
  width: 15vw;
  position: absolute;
  bottom: 6.9vw;
  right: 2vw;
}
.posadFooterFirst > p {
  color: #fff;

  font-family: "Montserrat";
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5vw;
}
.posadFooterFirst > span {
  color: #fff;

  font-family: "Montserrat";
  font-size: 1vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 8vw;
}
.msF {
  width: 1.8vw;
}
.posadFooterFirst {
  margin-top: 0vw;
}
.posadFooterSecond {
  display: flex;
  justify-content: flex-end;
}
.posadFooterSecond a {
  color: rgba(255, 255, 255, 0.4);

  font-family: "Montserrat";
  font-size: 0.7vw;
  text-decoration: none;
  margin-left: 2vw;
  margin-top: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.posadFooterFirst > p a {
  color: #fff;
  font-family: "Montserrat";
  font-size: 1.5vw;
  font-style: normal;
  text-decoration: none;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.logosFooter {
  width: 12vw;
}
.posadFooterFirst {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.posadFooter > div {
  width: 65vw;
  margin: 0vw auto;
}
.leftProj {
  width: 43vw;
}
.leftProj > div {
  display: flex;
}
.leftProj > div p {
  color: #2d2d2d;

  font-family: "Montserrat";
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 27vw;
  letter-spacing: 1.92px;
}
.main h1 img {
  margin-left: 1vw;
}
.miniLine {
  height: 4px;
  background: #a2ff00;
  width: 1.8vw;
  margin-right: 0.5vw;
  margin-top: 0.5vw;
}
.rightProj {
}
.rightProj .askPrice {
  font-size: 0.95vw;
  padding: 2.2vw 0;
  text-align: center;
  width: 19vw;
  margin: 0;
}
.shmi {
  display: flex;
  justify-content: center;
  margin-top: 0.7vw;
  align-items: center;
  color: #000;

  font-family: "Bicubik";
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.766px;
  text-transform: uppercase;
}
.shmi img {
  width: 1.5vw;
  margin: 0 0.5vw;
}
.leftProj > p {
  color: #fff;
  background: radial-gradient(
      128.44% 128.22% at 49.16% -21.78%,
      rgba(67, 69, 73, 0.5) 0%,
      rgba(67, 69, 73, 0) 100%
    ),
    #1c1d20;
  width: fit-content;
  margin-bottom: 1vw;
  box-shadow: 0px 4px 35px 0px rgba(39, 56, 87, 0.15);
  font-family: "Montserrat";
  padding: 1vw;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.57px;
  text-transform: uppercase;
}
.zadaccha p b {
  color: #806bff;
}
.typeCont li img {
  margin-right: 0.5vw;
  width: 2vw;
}
.main h1,
.main h1 span {
  color: #fff;

  text-shadow: 0px 0.7753304243087769px 1.5506608486175537px
    rgba(0, 37, 133, 0.79);
  font-family: "Bicubik";
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.504px;
}
h1 span svg {
  margin-left: 0vw;
  width: 4vw;
}
h1 span {
  display: flex;
  align-items: center;
}
.business {
  display: flex;
  align-items: center;
  color: #fff;
  background: #2b2c2f;
  padding: 1vw;
  width: fit-content;
  margin-top: 1vw;
  box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.25);
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 4.96px;
  text-transform: lowercase;
}
.business p {
  margin-right: 0.6vw;
}
.proff {
  color: #fff;

  font-size: 1vw;
  padding: 0.5vw 1vw;
  background: #2b2c2f;

  box-shadow: 0px 3.7296px 26.10723px 0px rgba(0, 0, 0, 0.25);
  width: fit-content;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.979px;
  text-transform: uppercase;
  margin-bottom: 1.5vw;
}
.headerLinks {
  display: flex;
  align-items: center;
}
.msF {
  margin: 0 0.5vw;
}
.headerLinks p {
  font-size: 0.8vw;
  margin-right: 0.5vw;
}
header {
  display: flex;
  align-items: center;
  padding: 2.2vw 0;
  color: #fff;
  width: 65vw;
  justify-content: space-between;
}
.headerBody {
  background: url("./fon.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 640px) {
  .typesCont {
    flex-direction: column;
    width: 94vw;
    margin: 0 auto;
  }
  .typeCont {
    margin-bottom: 6vw;
    padding: 5.5vw;
  }
  .typeCont > p {
    color: #2d2d2d;
    font-family: "Bicubik";
    font-size: 5.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    text-transform: uppercase;
  }
  header {
    display: flex;
    align-items: center;
    padding: 5vw 0;
    color: #fff;
    width: 94vw;
    justify-content: space-between;
  }
  .arris {
    display: block;
    margin: 0 auto;
    width: 5vw;
    margin-top: 24vw;
  }
  .mobik {
    position: absolute;
    width: 96vw;
    right: 1vw;
    top: 93vw;
  }
  .scroll {
    position: absolute;
    width: 19vw;
    top: 269vw;
    left: 50%;
    translate: -50% 0;
    z-index: 22;
  }
  .rightContent .prem {
    width: 70vw;
    position: absolute;
    bottom: -14vw;
    right: 24vw;
  }
  .proj {
    padding-bottom: 20vw;
  }
  .posadFooter {
    background-color: transparent !important;
    transform: translateY(-15vw);
  }
  header .logo {
    margin-right: 8vw;
  }
  header .logo img {
    width: 40vw;
  }
  .button-main {
    background: none;
    box-shadow: none;
    text-align: right;
    font-size: 3vw;
  }
  .clientMob {
    width: 10vw;
    margin: 0 1vw;
  }
  .headerLinks p {
    display: none;
  }
  .mess {
    display: none;
  }
  .main h1,
  .main h1 span {
    color: #fff;
    text-shadow: 0px 0.7753304243087769px 1.5506608486175537px
      rgba(0, 37, 133, 0.79);
    font-family: "Bicubik";
    font-size: 9vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.504px;
  }
  .proff {
    color: #fff;
    font-size: 3vw;
    padding: 1.5vw 2vw;
    background: #2b2c2f;
    box-shadow: 0px 3.7296px 26.10723px 0px rgba(0, 0, 0, 0.25);
    width: -moz-fit-content;
    width: fit-content;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.979px;
    text-transform: uppercase;
    margin-top: 10vw;
    margin-bottom: 2.5vw;
  }
  .business {
    display: flex;
    align-items: center;
    color: #fff;
    background: #2b2c2f;
    padding: 1vw 3vw;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 1vw;
    box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.25);
    font-size: 4.4vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5vw;
    text-transform: lowercase;
    margin-top: 5vw;
  }
  .askPrice {
    margin-top: 8vw;
    width: 94vw;
    font-family: "Bicubik";
    font-size: 4.3vw;
    text-align: center;
    background: linear-gradient(
        183deg,
        rgba(255, 255, 255, 0.7) 0.55%,
        rgba(70, 119, 244, 0) 9.38%
      ),
      radial-gradient(
        100% 100% at 50% 0%,
        rgba(255, 255, 255, 0.5) 2.77%,
        rgba(4, 159, 246, 0) 44.59%
      ),
      radial-gradient(
        100% 100% at 50% 0%,
        #49c1ff 0%,
        rgba(83, 124, 204, 0) 72.3%
      ),
      linear-gradient(0deg, #18306c 0%, rgba(40, 54, 129, 0) 75.23%), #4643f1;
    box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.42);
    padding: 7vw 4.2vw;
    color: #fff;
    width: -moz-fit-content;
    width: 94vw;
    letter-spacing: 3px;
    cursor: pointer;
    margin-bottom: 3vw;
  }
  .posadFooter {
    background-image: url(./mobFooter.png);
    padding-top: 40vw;
    margin-top: -15vw;
  }
  .video {
    position: absolute;
    top: 10vw;
    display: none;
    left: 3vw;
    animation: right 7s infinite;
  }
  .leftFon {
    display: none;
  }
  .rightFon {
    display: none;
  }
  .butSpan {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 3.5vw;
    margin-bottom: 8vw;
  }
  .butSpan div {
    width: 6vw;
    height: 0.5vw;
    background: #a2fe04;
    margin-right: 2.8vw;
  }
  .logosTable img {
    width: 101%;
    background: rgb(29, 29, 29);
    height: 101%;
  }
  h1 img {
    margin-left: 2vw;
    width: 8vw;
  }
  .logosTable {
    display: grid;
    grid-template-columns: 28.2vw 28.2vw 28.2vw;
    margin-top: 15vw;
  }
  .msF {
    margin: 0 2vw;
  }
  .headerBody {
    padding-bottom: 22vw;
  }
  .rightContent .cl {
    position: absolute;
    top: 1.3vw;
    left: -2.5vw;
    display: none;
  }
  .business img {
    width: 5vw;
    margin-top: 0.5vw;
    margin-left: 1vw;
  }
  .headerContent {
    width: 94vw;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }
  .typeCont li {
    display: flex;
    align-items: center;
    padding: 2.5vw 0;
    color: #000;
    font-family: "Montserrat";
    font-size: 5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
  }
  .posContent h2 {
    color: #1d1d1d;
    text-align: center;
    font-family: "Bicubik";
    font-size: 10.2vw;
    font-style: normal;
    font-weight: 400;
    margin-top: 20vw;
    line-height: 120%;
    letter-spacing: 0.5vw;
  }
  .leftProj {
    width: 100%;
  }
  .handPos {
    top: 5vw;
    left: -10vw;
    transform: rotate(38deg);
    width: 24vw;
    animation: unset;
  }
  .leftProj > p {
    color: #fff;
    background: radial-gradient(
        128.44% 128.22% at 49.16% -21.78%,
        rgba(67, 69, 73, 0.5) 0%,
        rgba(67, 69, 73, 0) 100%
      ),
      #1c1d20;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 1vw;
    box-shadow: 0px 4px 35px 0px rgba(39, 56, 87, 0.15);
    font-family: "Montserrat";
    padding: 4vw;
    font-size: 4vw;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.57px;
    text-transform: uppercase;
  }
  .leftProj > div p {
    color: #2d2d2d;
    font-family: "Montserrat";
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: auto;
    letter-spacing: 0.1vw;
  }
  .leftProj > div {
    display: flex;
    margin-top: 4vw;
  }
  .shmi {
    display: flex;
    justify-content: center;
    margin-top: 2.7vw;
    align-items: center;
    color: #000;
    font-family: "Bicubik";
    font-size: 5.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.766px;
    text-transform: uppercase;
  }
  .shmi img {
    width: 5.5vw;
    margin: 0 1.5vw;
  }
  .rightProj .askPrice {
    font-size: 4.5vw;
    padding: 6.2vw 0;
    text-align: center;
    width: 94vw;
    margin: 0;
    margin-top: 6vw;
  }
  .miniLine {
    height: 2px;
    background: #a2ff00;
    width: 22.8vw;
    margin-right: 4.5vw;
    margin-top: 1.5vw;
  }
  .proj {
    flex-direction: column;
    width: 94vw;
  }
  .zadaccha img {
    width: 18vw;
    margin-right: 0vw;
    position: relative;
    z-index: 2;
  }
  .headerBody {
    background: url(./mob3.png);
    background-size: cover;
    background-position: center;
  }
  .posContent {
    background: url(./mob4.png);
    background-size: cover;
    background-position: center 0;
  }
  .zadaccha p {
    background: #fff;
    padding: 0.9vw 2vw;
    box-shadow: 0px 4px 35px 0px rgba(39, 56, 87, 0.15);
    color: #2d2d2d;
    font-family: "Montserrat";
    font-size: 3.8vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.379px;
    text-transform: uppercase;
    padding: 3vw 5vw;
    padding-top: 10vw;
    margin-top: -6vw;
    text-align: center;
  }
  .zadaccha {
    flex-direction: column;
    align-items: center;
    width: 94vw;
    margin-bottom: 10vw;
  }
  .typeCont li img {
    margin-right: 2.5vw;
    width: 7vw;
  }
  .typeContLine {
    margin: 3vw 0;
    width: 100%;
    height: 2px;
    background: rgba(243, 243, 243, 1);
  }
}
